// Here you can add other styles
body {
    font-family: sans-serif;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.multiselect {
    width: 170px;
    font-size: 15px;
    padding-bottom: 4px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    outline: none;
}

.multiselect:hover {
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.multiselect.active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1px solid transparent;
}

.multiselect > .title {
    cursor: pointer;
    height: 16px;
    padding: 6px;
}

.multiselect > .title > .text {
    max-width: 130px;
    max-height: 25px;
    display: block;
    float: left;
    overflow: hidden;
    line-height: 1.3em;
}

.multiselect > .title > .expand-icon,
.multiselect > .title > .close-icon {
    float: right;
    border-radius: 50%;
    padding: 0 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 700;
    transition: 0.2s;
    display: none;
}

.multiselect.selection > .title > .expand-icon {
    display: none;
}

.multiselect > .title > .expand-icon,
.multiselect.selection > .title > .close-icon {
    display: block;
}

.multiselect > .title > .close-icon:hover {
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: rgb(203, 32, 32);
    color: #fff;
}

.multiselect > .container {
    max-height: 200px;
    overflow: auto;
    margin-top: 4px;
    margin-left: -1px;
    width: 170px;
    transition: 0.2s;
    position: absolute;
    z-index: 99;
    background: #fff;
    border: 1px solid transparent;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.multiselect.active > .container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: 0;
}

.multiselect:hover > .container {
    border-top-color: rgba(0, 0, 0, 0.3);
}

.multiselect.active:hover > .container {
    border-color: rgba(0, 0, 0, 0.3);
}

.multiselect > .container > option {
    display: none;
    padding: 5px;
    cursor: pointer;
    transition: 0.2s;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.multiselect > .container > option.selected {
    background: rgb(122, 175, 233);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #fff;
}

.multiselect > .container > option:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #000;
}

.multiselect.active > .container > option {
    display: block;
}
*::-webkit-scrollbar{
    display: none;
}

.row-seperator{
    margin-top: 10px !important;
}
.suggestion {
    background-color: #eee;
    padding: 2px;
    margin: 2px;
    display: inline-block;
    cursor: pointer;
  }

  .icn-spinner {
    animation: spin-animation 0.5s infinite;
  }
  
  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }